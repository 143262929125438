import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { JqxGridModule } from '../../ng-modules/jqx-grid/jqx-grid.module';

import { ReporteTrackingFacturasNacionalesComponent } from './reporte-tracking-facturas-nacionales/reporte-tracking-facturas-nacionales.component';
import { GridTrackingFacturasNacionalesComponent } from './grid-tracking-facturas-nacionales/grid-tracking-facturas-nacionales.component';
import { GridAjustesPrecioComponent } from './grid-ajustes-precio/grid-ajustes-precio.component';
import { GridAjustesCargaComponent } from './grid-ajustes-carga/grid-ajustes-carga.component';
import { GridOrdenCompraComponent } from './grid-orden-compra/grid-orden-compra.component';
import { GridCodigoProveedorComponent } from './grid-codigo-proveedor/grid-codigo-proveedor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'modal';
import { FileshareModule } from 'fileshare';

const routes: Routes = [
  { path: 'home/Index', component: ReporteTrackingFacturasNacionalesComponent }
]
const routing = RouterModule.forChild(routes);

@NgModule({
  declarations: [ReporteTrackingFacturasNacionalesComponent, GridTrackingFacturasNacionalesComponent, GridAjustesPrecioComponent, GridAjustesCargaComponent, GridOrdenCompraComponent, GridCodigoProveedorComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    routing,
    JqxGridModule,
    NgSelectModule,
    ModalModule,
    FileshareModule.forRoot({ Https: true })   
  ]
})

export class ReporteTrackingFacturasNacionalesModule { }
