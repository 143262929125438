import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReporteTrackingFacturasNacionalesComponent } from './screen/reporte-tracking-facturas-nacionales - Copy/reporte-tracking-facturas-nacionales/reporte-tracking-facturas-nacionales.component';
import { RequisasComponent } from './screen/requisas/requisas/requisas.component';
import { VestaMenuComponent } from 'vesta-menu';
import { AccesoDenegadoComponent } from 'vesta';
import { AuthorizationGuard } from 'vesta-authentication';

const routes: Routes = [
  { path: "", redirectTo: "menu" , pathMatch: "full" },
  {
    path: "login/accessdenied", component: AccesoDenegadoComponent
  },
  {
    path: "menu",
    component: VestaMenuComponent, canActivate: [AuthorizationGuard]
  },
  {
    path: 'mantenimiento', children: [
      {
        path: "asignacióndesegmentos",
        loadChildren: "../app/screen/asignacion-segmentos/asignacion-segmentos.module#AsignacionSegmentosModule",
        canActivate: [AuthorizationGuard]
      }
    ]
  },
  {
    path: 'operaciones', children: [
      {
        path: 'gestiones',
        loadChildren: "../app/screen/gestiones/gestiones.module#GestionesModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "ajustesporrecepcióndecarga",
        loadChildren: "../app/screen/revision-ajuste/revision-ajuste.module#RevisionAjusteModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "solicitudderequisa",
        loadChildren: "../app/screen/solicitudmateria-prima/solicitudmateria-prima.module#SolicitudmateriaPrimaModule",
        canActivate: [AuthorizationGuard]
      },      
      {
        path: "ingresodefacturas",
        loadChildren: "../app/screen/revision-ingreso/revision-ingreso.module#RevisionIngresoModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "registrosmigo",
        loadChildren: "../app/screen/registro-migo/registro-migo.module#RegistroMigoModule"
      }
    ]
  },  
  {
    path: 'reportes', children: [
      {
        path: "reportepicking",
        loadChildren: "../app/screen/picking/picking.module#PickingModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportemigo",
        loadChildren: "../app/screen/migo-sap/migo-sap.module#MigoSapModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportemiro",
        loadChildren: "../app/screen/miro-sap/miro-sap.module#MiroSapModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportetraslados",
        loadChildren: "../app/screen/traslados-sap/traslados-sap.module#TrasladosSapModule",
        canActivate: [AuthorizationGuard]
      }
    ]
  },
  {
    path: 'consultas', children: [
      {
        path: "gestióndeinventario",
        //loadChildren: "../app/screen/gestion-inventario-copia/gestion-inventario-copia.module#GestionInventarioCopiaModule",
        loadChildren: "../app/screen/inventarios/inventarios.module#InventariosModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "cortediario",
        loadChildren: "../app/screen/corte-diario/corte-diario.module#CorteDiarioModule",
        canActivate: [AuthorizationGuard]
      },
      {
        path: "reportetrackingfacturas",
        loadChildren: "../app/screen/reporte-tracking-facturas-nacionales/reporte-tracking-facturas-nacionales.module#ReporteTrackingFacturasNacionalesModule",
        canActivate: [AuthorizationGuard]
      }
    ]
  },
  { path: 'reportefacturastracking', component: ReporteTrackingFacturasNacionalesComponent },
  {
    path: "requisas",
    loadChildren: "../app/screen/requisas/requisas.module#RequisasModule"
  }
  //,
  //{
  //  path: "gestioninventario",
  //  loadChildren: "../app/screen/gestion-inventario/gestion-inventario.module#GestionInventarioModule",
  //}
  //,
  //{
  //  path: "gestioninventariocopia",
  //  loadChildren: "../app/screen/gestion-inventario-copia/gestion-inventario-copia.module#GestionInventarioCopiaModule",
  //}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
