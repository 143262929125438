import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Grid } from '../../../objetos/reporte-tracking-facturas-ncionales-object';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-tracking-facturas-nacionales',
  templateUrl: './grid-tracking-facturas-nacionales.component.html',
  styleUrls: ['./grid-tracking-facturas-nacionales.component.css']
})
export class GridTrackingFacturasNacionalesComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridTrackingFacturasNacionales', { static: false }) myGridTrackingFacturasNacionales: jqxGridComponent;
  @Input('data') data: Array<Grid> = [];

  ngOnInit() {
    this.source.localdata = this.data;
    this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Sku', type: 'string' },
        { name: 'SkuCodigo', type: 'string' },
        { name: 'CantidadFacturada', type: 'number' },
        { name: 'CantidadRecibida', type: 'number' },
        { name: 'UnidadDeMedida', type: 'string' },
        { name: 'OrdendeCompra', type: 'string' },
        { name: 'Observacion', type: 'string' },
        { name: 'Ajustes', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Sku', datafield: 'Sku', cellsalign: 'left', align: 'center', width: '23.5%' },
      { text: 'Código', datafield: 'SkuCodigo', cellsalign: 'center', align: 'center', width: '9%' },
      { text: 'Cantidad Facturada', datafield: 'CantidadFacturada', cellsalign: 'center', align: 'center', filterable: false, width: '14%' },
      { text: 'Cantidad Recibida', datafield: 'CantidadRecibida', cellsalign: 'center', align: 'center', filterable: false, width: '13%' },
      { text: 'UM', datafield: 'UnidadDeMedida', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'OC', datafield: 'OrdendeCompra', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Observación', datafield: 'Observacion', cellsalign: 'left', align: 'center', filterable: false, width: '13.5%' },
      { text: 'Ajustes', datafield: 'Ajustes', cellsalign: 'center', align: 'center', filterable: false, width: '7%' }
    ];

}
