import { Component, OnInit, ViewChild } from '@angular/core';
import { ReporteTrackingFacturasNacional, Grid, GridPrecio, GridCarga, Settingse, DataOrdenDeCompraYCodigoproveedor } from '../../../objetos/reporte-tracking-facturas-ncionales-object';
import { GridTrackingFacturasNacionalesComponent } from '../grid-tracking-facturas-nacionales/grid-tracking-facturas-nacionales.component';
import { GridAjustesPrecioComponent } from '../grid-ajustes-precio/grid-ajustes-precio.component';
import { GridAjustesCargaComponent } from '../grid-ajustes-carga/grid-ajustes-carga.component';
import { GridOrdenCompraComponent } from '../grid-orden-compra/grid-orden-compra.component';
import { GridCodigoProveedorComponent } from '../grid-codigo-proveedor/grid-codigo-proveedor.component';
import * as Enumerable from 'linq';
import { environment } from '../../../../environments/environment';
import { DialogService, CallHttp } from 'vesta';
import { ModalComponent } from 'modal';
import { FileshareComponent } from 'fileshare';
import * as moment from 'moment';
require("../../../extensions/moment-extends");

@Component({
  selector: 'app-reporte-tracking-facturas-nacionales',
  templateUrl: './reporte-tracking-facturas-nacionales.component.html',
  styleUrls: ['./reporte-tracking-facturas-nacionales.component.css']
})
export class ReporteTrackingFacturasNacionalesComponent implements OnInit {

  constructor(private DialogService: DialogService, private CallHttp: CallHttp) { }
  @ViewChild(GridTrackingFacturasNacionalesComponent, { static: false }) myGridTrackingFacturasNacionales: GridTrackingFacturasNacionalesComponent;
  @ViewChild(GridAjustesPrecioComponent, { static: false }) myGridAjustesPrecio: GridAjustesPrecioComponent;
  @ViewChild(GridAjustesCargaComponent, { static: false }) myGridAjustesCarga: GridAjustesCargaComponent;
  @ViewChild(GridOrdenCompraComponent, { static: false }) myGridOrdenCompra: GridOrdenCompraComponent;
  @ViewChild(GridCodigoProveedorComponent, { static: false }) myGridCodigoProveedor: GridCodigoProveedorComponent;
  @ViewChild("modalAjustePrecioCarga", { static: false }) modalAjustePrecioCarga: ModalComponent;
  @ViewChild("modalOrdenCompra", { static: false }) modalOrdenCompra: ModalComponent;
  @ViewChild("modalCodigoProveedor", { static: false }) modalCodigoProveedor: ModalComponent;
  @ViewChild(FileshareComponent, { static: false }) fileShare: FileshareComponent;

  Info: ReporteTrackingFacturasNacional;

  //Variable ngIf
  MostrarTemplateNacional: boolean = false;
  MostrarTemplateInternacional: boolean = false;

  //Variable textbox
  Factura: string;

  //Variables pantalla
  FacturaResponse: string;
  FechaFactura: string;
  Vencimiento: string;
  PersonaNombre: string;
  DiasCredito: number; 
  CodigoProveedor: number;
  Gestion: string;
  CR: string;
  Ajustes: number;
  TotalFacturada: string;
  Grid: Array<Grid> = [];
  AjustesArray: Array<Settingse> = [];
  AjustesPrecio: Array<GridPrecio> = [];
  AjustesCarga: Array<GridCarga> = [];

  //Variables sellos
  Entrada: string;
  Salida: string;

  Aduana: string;

  EntradaFin: string;
  SalidaFin: string;

  Recepcion: string;
  FechaMigo: string;
  FechaMiro: string;

  OrdendeCompraInput: string;
  CodigoProveedorInput: string;

  ngOnInit() {
    this.Factura = null;
  }  

  DataSkuFactura: Array<Grid> = [];
  TotalDiferencia: string;
  BuscarFactura() {
    if (this.Factura == null || this.Factura == "") {
      this.DialogService.NotificacionWarning("Digite una factura");
    } else {
      this.CallHttp.httpGet<ReporteTrackingFacturasNacional>(environment.webServices.WareHouse + "ReporteTrackingFactura/ReporteTrackingFacturaByNumeroFactura?NumeroFactura=" + this.Factura, null, { withCredentials: false })
        .subscribe(response => {

          let obj = {
            Id: response.Id,
            Factura: response.Factura,
            Bultos: response.Bultos,
            ImporteTotal: response.ImporteTotal,
            BultosDisponible: response.BultosDisponible,
            TipoISV: response.TipoISV,
            CAI: response.CAI,
            DUA: response.DUA,
            FechaDUA: response.FechaDUA,
            FacturaId: response.FacturaId,
            TiempoDeCredito: response.TiempoDeCredito,
            Kilos: response.Kilos,
            Miro: response.Miro,
            FechaMiro: response.FechaMiro,
            FechaMigo: response.FechaMigo,
            FechaFactura: response.FechaFactura,
            Vencimiento: response.Vencimiento,
            Migo: response.Migo,
            NotaEntrega: response.NotaEntrega,
            Enabled: response.Enabled,
            IsSoftDeleted: response.IsSoftDeleted,
            CantidadAjuste: response.CantidadAjuste,
            TotalFactura: response.TotalFactura,
            TotalDiferencia: response.TotalDiferencia,
            CodigoProveedor: response.CodigoProveedor,
            TipoFacturaNacionalInternacional: response.TipoFacturaNacionalInternacional,
            Recepcion: {
              Id: response.Recepcion.Id,
              PersonaClienteId: response.Recepcion.PersonaClienteId,
              GestionId: response.Recepcion.GestionId,
              PersonaId: response.Recepcion.PersonaId,
              PersonaNombre: response.Recepcion.PersonaNombre,
              NumeroGestion: response.Recepcion.NumeroGestion,
              NumeroPedido: response.Recepcion.NumeroPedido,
              PaisOrigenId: response.Recepcion.PaisOrigenId,
              SitioOrigenId: response.Recepcion.SitioOrigenId,
              SitioOrigenNombre: response.Recepcion.SitioOrigenNombre,
              SitioDestinoId: response.Recepcion.SitioDestinoId,
              SitioDestinoNombre: response.Recepcion.SitioDestinoNombre,
              FechaRecepcion: response.Recepcion.FechaRecepcion,
              Entrada: response.Recepcion.Entrada,
              Salida: response.Recepcion.Salida,
              EntradaFin: response.Recepcion.EntradaFin,
              SalidaFin: response.Recepcion.SalidaFin,
              Eta: response.Recepcion.Eta,
              SitioEntradaSalida: response.Recepcion.SitioEntradaSalida,
              SitioEntradaFinSalidaFin: response.Recepcion.SitioEntradaFinSalidaFin,
            },
            Collectiones: response.Collectiones,
            Settingse: response.Settingse
          }

          this.Entrada = obj.Recepcion.Entrada == null ? '-----' : moment(obj.Recepcion.Entrada).FormatoFechaCSharp();
          this.Salida = obj.Recepcion.Salida == null ? '-----' : moment(obj.Recepcion.Salida).FormatoFechaCSharp();

          this.Aduana = '2021-12-30 21:45:00' + "**";

          this.EntradaFin = obj.Recepcion.EntradaFin == null ? '-----' : moment(obj.Recepcion.EntradaFin).FormatoFechaCSharp();
          this.SalidaFin = obj.Recepcion.SalidaFin == null ? '-----' : moment(obj.Recepcion.SalidaFin).FormatoFechaCSharp();

          this.Recepcion =  moment(obj.Recepcion.FechaRecepcion).FormatoFechaCSharp();
          this.FechaMiro =  moment(obj.FechaMiro).FormatoFechaCSharp();
          this.FechaMigo =  moment(obj.FechaMigo).FormatoFechaCSharp();          

          /////----------/////
          //this.Grid = [];
          this.DataSkuFactura = [];
          this.AjustesArray = [];
          this.AjustesPrecio = [];
          this.AjustesCarga = [];
          this.FacturaResponse = obj.Factura;
          this.FechaFactura = moment(obj.FechaFactura).FormatoFechaCSharp();
          this.Vencimiento = moment(obj.Vencimiento).FormatoFechaCSharp();
          this.PersonaNombre = obj.Recepcion.PersonaNombre;
          this.DiasCredito = obj.TiempoDeCredito;
          this.CodigoProveedor = obj.CodigoProveedor;
          this.Gestion = obj.Recepcion.NumeroGestion;
          this.CR = obj.NotaEntrega;
          this.Ajustes = obj.CantidadAjuste;
          this.TotalFacturada = obj.TotalFactura.toFixed(2);
          this.TotalDiferencia = Number(obj.TotalDiferencia).toFixed(2);

          //this.Grid = Enumerable.from(obj.Collectiones).select(s => {
          //  return {
          //    Sku: s.Sku,
          //    SkuCodigo: s.SkuCodigo,
          //    CantidadFacturada: s.Cantidad,
          //    CantidadRecibida: s.CantidadRecibida,
          //    UnidadDeMedida: s.UnidadMedida,
          //    OrdendeCompra: s.OC,
          //    Ajustes: obj.Collectiones.length == 0 ? 0 : obj.Collectiones.length,
          //    DiasCredito: obj.TiempoDeCredito
          //  }
          //}).orderBy(o => o.SkuCodigo).toArray();

          this.AjustesArray = obj.Settingse;
          this.DataSkuFactura = Enumerable.from(obj.Collectiones).select(s => {
            return {
              Sku: s.Sku,
              SkuCodigo: s.SkuCodigo,
              CantidadFacturada: s.Cantidad,
              CantidadRecibida: s.CantidadRecibida,
              UnidadDeMedida: s.UnidadMedida,
              OrdendeCompra: s.OC,
              Observacion: s.Observacion,
              Ajustes: s.Settingse.length == 0 ? 0 : s.Settingse.length
            }
          }).orderBy(o => o.SkuCodigo).toArray();

          if (obj.TipoFacturaNacionalInternacional == false) {
            this.MostrarTemplateNacional = true;
          } else {
            this.MostrarTemplateInternacional = true;
          }
          
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }    
  }
  
  VerAjustes() {
    if (this.AjustesArray.length == 0) {
      this.DialogService.NotificacionWarning("No hay ajustes disponibles para mostrar");
    } else {
      for (var i = 0; i < this.AjustesArray.length; i++) {
        if (this.AjustesArray[i].Reclamo.Value == 7) {
          let objPrecio = {
            Sku: this.AjustesArray[i].Sku,
            Cantidad: this.AjustesArray[i].Cantidad,
            UnidadMedida: this.AjustesArray[i].UnidadMedida == null ? '---' : this.AjustesArray[i].UnidadMedida,
            PrecioOc: this.AjustesArray[i].PrecioOc,
            PrecioFactura: this.AjustesArray[i].PrecioFactura,
            DiferenciaPrecio: this.AjustesArray[i].Diferencia,
          }
          this.AjustesPrecio.push(objPrecio);
        } else {
          let objCarga = {
            Sku: this.AjustesArray[i].Sku,
            Cantidad: this.AjustesArray[i].Cantidad,
            UnidadMedida: this.AjustesArray[i].UnidadMedida,
            PrecioOc: this.AjustesArray[i].PrecioOc,
            PrecioFactura: this.AjustesArray[i].PrecioFactura,
            Reclamo: this.AjustesArray[i].Reclamo.DisplayName,
            Observacion: this.AjustesArray[i].Observacion,
          }
          this.AjustesCarga.push(objCarga);
        }
      }

      this.myGridAjustesPrecio.source.localdata = Enumerable.from(this.AjustesPrecio).orderBy(o => o.Sku).toArray();
      this.myGridAjustesPrecio.dataAdapter.dataBind();

      this.myGridAjustesCarga.source.localdata = Enumerable.from(this.AjustesCarga).orderBy(o => o.Sku).toArray();
      this.myGridAjustesCarga.dataAdapter.dataBind();

      this.modalAjustePrecioCarga.showModal();
    }    
  }

  AbrirModalOrdenDeComprar() {
    this.OrdendeCompraInput = null;
    this.myGridOrdenCompra.myGridOrdenCompra.clear();
    this.modalOrdenCompra.showModal();
  }

  ClearInput() {
    this.Factura = null;
    this.MostrarTemplateNacional = false;
    this.MostrarTemplateInternacional = false
  }

  ClearInputOC() {
    this.OrdendeCompraInput = null;
    this.myGridOrdenCompra.myGridOrdenCompra.clear();
  }

  AbrirModalCodigoproveedor() {
    this.CodigoProveedorInput = null;
    this.myGridCodigoProveedor.myGridCodigoProveedor.clear();
    this.modalCodigoProveedor.showModal();
  }

  ClearInputCP() {
    this.CodigoProveedorInput = null;
    this.myGridCodigoProveedor.myGridCodigoProveedor.clear();
  }

  VerDocumentos() {
    let List: Array<string> = [];
    List.push(this.Factura);
    this.fileShare.DownloadListDocumento(List);
  }

  BuscarOC() {
    this.CallHttp.httpGet<Array<DataOrdenDeCompraYCodigoproveedor>>(environment.webServices.WareHouse + "ReporteTrackingFactura/FacturaByOc?OC=" + this.OrdendeCompraInput, null, { withCredentials: false })
      .subscribe(response => {
        this.myGridOrdenCompra.source.localdata = Enumerable.from(response).orderBy(o => o.IdProveedor).toArray();
        this.myGridOrdenCompra.dataAdapter.dataBind();
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  BuscarCP() {
    this.CallHttp.httpGet<Array<DataOrdenDeCompraYCodigoproveedor>>(environment.webServices.WareHouse + "ReporteTrackingFactura/FacturaByIdProveedor?IdProveedor=" + this.CodigoProveedorInput, null, { withCredentials: false })
      .subscribe(response => {
        this.myGridCodigoProveedor.source.localdata = Enumerable.from(response).orderBy(o => o.IdProveedor).toArray();
        this.myGridCodigoProveedor.dataAdapter.dataBind();
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  VerFacturaOrdenCompraYCodigoProveedor(event) {
    //this.modalOrdenCompra.closeModal();
    this.CallHttp.httpGet<ReporteTrackingFacturasNacional>(environment.webServices.WareHouse + "ReporteTrackingFactura/ReporteTrackingFacturaByNumeroFactura?NumeroFactura=" + event.NoFactura, null, { withCredentials: false })
      .subscribe(response => {

        let obj = {
          Id: response.Id,
          Factura: response.Factura,
          Bultos: response.Bultos,
          ImporteTotal: response.ImporteTotal,
          BultosDisponible: response.BultosDisponible,
          TipoISV: response.TipoISV,
          CAI: response.CAI,
          DUA: response.DUA,
          FechaDUA: response.FechaDUA,
          FacturaId: response.FacturaId,
          TiempoDeCredito: response.TiempoDeCredito,
          Kilos: response.Kilos,
          Miro: response.Miro,
          FechaMiro: response.FechaMiro,
          FechaMigo: response.FechaMigo,
          FechaFactura: response.FechaFactura,
          Vencimiento: response.Vencimiento,
          Migo: response.Migo,
          NotaEntrega: response.NotaEntrega,
          Enabled: response.Enabled,
          IsSoftDeleted: response.IsSoftDeleted,
          CantidadAjuste: response.CantidadAjuste,
          TotalFactura: response.TotalFactura,
          TotalDiferencia: response.TotalDiferencia,
          CodigoProveedor: response.CodigoProveedor,
          TipoFacturaNacionalInternacional: response.TipoFacturaNacionalInternacional,
          Recepcion: {
            Id: response.Recepcion.Id,
            PersonaClienteId: response.Recepcion.PersonaClienteId,
            GestionId: response.Recepcion.GestionId,
            PersonaId: response.Recepcion.PersonaId,
            PersonaNombre: response.Recepcion.PersonaNombre,
            NumeroGestion: response.Recepcion.NumeroGestion,
            NumeroPedido: response.Recepcion.NumeroPedido,
            PaisOrigenId: response.Recepcion.PaisOrigenId,
            SitioOrigenId: response.Recepcion.SitioOrigenId,
            SitioOrigenNombre: response.Recepcion.SitioOrigenNombre,
            SitioDestinoId: response.Recepcion.SitioDestinoId,
            SitioDestinoNombre: response.Recepcion.SitioDestinoNombre,
            FechaRecepcion: response.Recepcion.FechaRecepcion,
            Entrada: response.Recepcion.Entrada,
            Salida: response.Recepcion.Salida,
            EntradaFin: response.Recepcion.EntradaFin,
            SalidaFin: response.Recepcion.SalidaFin,
            Eta: response.Recepcion.Eta,
            SitioEntradaSalida: response.Recepcion.SitioEntradaSalida,
            SitioEntradaFinSalidaFin: response.Recepcion.SitioEntradaFinSalidaFin,
          },
          Collectiones: response.Collectiones,
          Settingse: response.Settingse
        }

        this.Entrada = obj.Recepcion.Entrada == null ? '-----' : moment(obj.Recepcion.Entrada).FormatoFechaCSharp();
        this.Salida = obj.Recepcion.Salida == null ? '-----' : moment(obj.Recepcion.Salida).FormatoFechaCSharp();

        this.Aduana = '2021-12-30 21:45:00';

        this.EntradaFin = obj.Recepcion.EntradaFin == null ? '-----' : moment(obj.Recepcion.EntradaFin).FormatoFechaCSharp();
        this.SalidaFin = obj.Recepcion.SalidaFin == null ? '-----' : moment(obj.Recepcion.SalidaFin).FormatoFechaCSharp();

        this.Recepcion = moment(obj.Recepcion.FechaRecepcion).FormatoFechaCSharp();
        this.FechaMiro = moment(obj.FechaMiro).FormatoFechaCSharp();
        this.FechaMigo = moment(obj.FechaMigo).FormatoFechaCSharp();

        /////----------/////
        //this.Grid = [];
        this.DataSkuFactura = [];
        this.AjustesArray = [];
        this.AjustesPrecio = [];
        this.AjustesCarga = [];
        this.FacturaResponse = obj.Factura;
        this.FechaFactura = moment(obj.FechaFactura).FormatoFechaCSharp();
        this.Vencimiento = moment(obj.Vencimiento).FormatoFechaCSharp();
        this.PersonaNombre = obj.Recepcion.PersonaNombre;
        this.DiasCredito = obj.TiempoDeCredito;
        this.CodigoProveedor = obj.CodigoProveedor;
        this.Gestion = obj.Recepcion.NumeroGestion;
        this.CR = obj.NotaEntrega;
        this.Ajustes = obj.CantidadAjuste;
        this.TotalFacturada = obj.TotalFactura.toFixed(2);
        this.TotalDiferencia = Number(obj.TotalDiferencia).toFixed(2);

        //this.Grid = Enumerable.from(obj.Collectiones).select(s => {
        //  return {
        //    Sku: s.Sku,
        //    SkuCodigo: s.SkuCodigo,
        //    CantidadFacturada: s.Cantidad,
        //    CantidadRecibida: s.CantidadRecibida,
        //    UnidadDeMedida: s.UnidadMedida,
        //    OrdendeCompra: s.OC,
        //    Ajustes: obj.Collectiones.length == 0 ? 0 : obj.Collectiones.length,
        //    DiasCredito: obj.TiempoDeCredito
        //  }
        //}).orderBy(o => o.SkuCodigo).toArray();

        this.AjustesArray = obj.Settingse;
        this.DataSkuFactura = Enumerable.from(obj.Collectiones).select(s => {
          return {
            Sku: s.Sku,
            SkuCodigo: s.SkuCodigo,
            CantidadFacturada: s.Cantidad,
            CantidadRecibida: s.CantidadRecibida,
            UnidadDeMedida: s.UnidadMedida,
            OrdendeCompra: s.OC,
            Observacion: s.Observacion,
            Ajustes: s.Settingse.length == 0 ? 0 : s.Settingse.length
          }
        }).orderBy(o => o.SkuCodigo).toArray();

        if (obj.TipoFacturaNacionalInternacional == false) {
          this.MostrarTemplateNacional = true;
        } else {
          this.MostrarTemplateInternacional = true;
        }

      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }
}
