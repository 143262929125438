import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { DataOrdenDeCompraYCodigoproveedor } from '../../../objetos/reporte-tracking-facturas-ncionales-object';

@Component({
  selector: 'app-grid-codigo-proveedor',
  templateUrl: './grid-codigo-proveedor.component.html',
  styleUrls: ['./grid-codigo-proveedor.component.css']
})
export class GridCodigoProveedorComponent implements OnInit {

  constructor() { }

  @ViewChild('myGridCodigoProveedor', { static: false }) myGridCodigoProveedor: jqxGridComponent;
  @Output() VerFacturaCodigoProveedor = new EventEmitter();

  ngOnInit() {
  }

  //Boton de eventos
  CellClick(event: any) {
    let rowData = event.args.row.bounddata;
    let dataField = event.args.datafield;

    if (dataField == GridCodigoProveedorColumns.VerFacturaClick) this.VerFacturaClick(rowData);
  }

  //Boton para SAP Migo
  VerFacturaCellRenderer = (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
    return '<div class="jqx-grid-cell-middle-align" style="margin-top: 0.18rem;"><img style="cursor: pointer" height="30" width="30" src="../../../../assets/icons/search.png"></div>'
  }

  VerFacturaClick(rowData: DataOrdenDeCompraYCodigoproveedor) {
    this.VerFacturaCodigoProveedor.emit(rowData);
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'NoFactura', type: 'string' },
        { name: 'NotaEntrega', type: 'string' },
        { name: 'IdProveedor', type: 'number' },
        { name: 'Proveedor', type: 'string' },
        { name: 'Estado', type: 'number', map: 'Estado>DisplayName' }
      ],
      id: "IdProveedor",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Código proveedor', datafield: 'IdProveedor', cellsalign: 'center', align: 'center', width: '13%' },
      { text: 'Proveedor', datafield: 'Proveedor', cellsalign: 'left', align: 'center', width: '30%' },
      { text: '# Factura', datafield: 'NoFactura', cellsalign: 'left', align: 'center', width: '20%' },
      { text: 'CR', datafield: 'NotaEntrega', cellsalign: 'center', align: 'center', width: '15%' },
      { text: 'Estado', datafield: 'Estado', cellsalign: 'center', align: 'center', width: '14%' },
      { text: "Ver Factura", datafield: "VerFacturaClick", cellsalign: 'center', align: "center", width: '8%', filterable: false, cellsrenderer: this.VerFacturaCellRenderer }
    ];

}

enum GridCodigoProveedorColumns {
  VerFacturaClick = 'VerFacturaClick'
}
