import { Component, OnInit, Inject } from '@angular/core';

import { ProximasSalidasVm, TarjetaProximasSalidas, CambioTurno, ResponseCambioTurnoVm, UsuarioPriorizadoYNoPriorizadoVm} from '../requisas-object';

import { environment } from '../../../../environments/environment';
import { CallHttp, DialogService } from 'vesta';
import { USER_AUTH, IUserAuth } from 'vesta-authentication';

import * as Enumerable from 'linq';
import { Posiciones } from 'src/app/objetos/inventarios-object';

@Component({
  selector: 'app-requisas',
  templateUrl: './requisas.component.html',
  styleUrls: ['./requisas.component.css']
})

export class RequisasComponent implements OnInit {

  constructor(private CallHttp: CallHttp, private DialogService: DialogService, @Inject(USER_AUTH) private user: IUserAuth) { }

  ngOnInit() {
    this.CargarValidacionUsuario();
    this.ListaPalletsEnSitio;
  }

  ListaPalletsEnSitio: PalletsEnSitio[] = [
    {
      Id: 5,
      Gestion: "PD-SPS-0001-04-11",
      Descripcion: "Papa Fresca Para Procesar",
      PosicionSalida: 5,
      Factura: "23-4560",
      Peso: "50000.00",
      RealizadoPor: "Lisa"
    },
    {
      Id: 6,
      Gestion: "PD-SPS-0001-04-12",
      Descripcion: "Papa Fresca Para Procesar",
      PosicionSalida: 6,
      Factura: "23-4561",
      Peso: "25300.00",
      RealizadoPor: "Lisa"
    },
    {
      Id: 7,
      Gestion: "PD-SPS-0001-04-13",
      Descripcion: "Papa Fresca Para Procesar",
      PosicionSalida: 7,
      Factura: "23-4562",
      Peso: "14200.00",
      RealizadoPor: "Lisa"
    }
  ]

  //Cargar validacion de usuario
  userPriorizadoYNoPriorizado: boolean = false;
  CargarValidacionUsuario() {
    let IdUsuario = this.user.Id;

    this.CallHttp.httpGet<UsuarioPriorizadoYNoPriorizadoVm>(environment.webServices.WareHouse + 'Relations/PermisoByUsiario?UsuarioId=' + IdUsuario, null)
      .subscribe(response => {
        this.userPriorizadoYNoPriorizado = response.PriorizarTurnoSalida;
        this.CargarProximasSalidas();
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  //Cargar tarjetas de proximas salidas
  DataProximasSalidas: Array<TarjetaProximasSalidas> = [];
  totalProximasSalidas: number;
  CargarProximasSalidas() {
    let obj = {
      SitioId: '2c7f282b-9bcb-4fd0-9a1d-22afe07cc0da'
    }

    this.CallHttp.httpPost<Array<ProximasSalidasVm>>(environment.webServices.WareHouse + 'Pallet/PalletBySitioId', obj)
      .subscribe(response => {
        if (response.length != 0) {
          this.DataProximasSalidas = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              NumeroPallet: s.NumeroPallet,
              Sku: s.Collectiones[0].Sku,
              TurnoDeSalida: s.TurnoDeSalida,
              NuevaPosicionSalida: s.TurnoDeSalida,
              Factura: s.Collectiones[0].Factura,
              Kilos: s.Collectiones[0].Cantidad.toFixed(2),
              Variedad: s.Variedad
            }
          }).orderBy(o => o.TurnoDeSalida).toArray();
          this.totalProximasSalidas = this.DataProximasSalidas.length;
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  //Buscar proximas salidas
  searchProximaSalida: string = null;
  searchProximasSalidas(name: string) {
    this.searchProximaSalida = name;
  }

  //Buscar pallets en sitio
  searchPalletEnSitio: string = null;
  searchPalletsEnSitio(name: string) {
    this.searchPalletEnSitio = name;
  }

  //change habilitar/deshablitar cambio de turno de pallet
  enabledDisabledImg: boolean = false;
  //confirmarCambiosTurnos: boolean = false;
  ChangeEnabledDisabled() {
    this.enabledDisabledImg = this.enabledDisabledImg == false ? true : false;

    if (this.enabledDisabledImg == false) {
      let cambioTurnoArray: Array<CambioTurno> = []

      cambioTurnoArray = Enumerable.from(this.DataProximasSalidas).select(s => {
        return {
          PalletId: s.Id,
          Pallet: s.NumeroPallet,
          TurnoDeSalida: s.TurnoDeSalida
        }
      }).orderBy(o => o.TurnoDeSalida).toArray();
      console.log(cambioTurnoArray);

      let obj = {
        CambioTurnoSalida: cambioTurnoArray
      }

      this.CallHttp.httpPost<ResponseCambioTurnoVm>(environment.webServices.WareHouse + 'Pallet/AsignarNewTurnoSalida', obj)
        .subscribe(response => {
          if (response.Id.length != 0) {
            this.CargarProximasSalidas();
            this.DialogService.NotificacionSucess("Cambios de turno realizados con éxito");
          } else {
            this.DialogService.NotificacionWarning(String(response.Id));
          }
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    } else {
      this.DialogService.NotificacionSucess("Habilitado para modificar");
    }
  }

  SubirTarjeta(id: string) {
    if (this.enabledDisabledImg == true) {
      let indice = this.DataProximasSalidas.findIndex(tab => tab.Id === id);
      // Si no se encuentra el elemento, devolver null
      if (indice === -1) {
        console.log("Indice no encontrado")
        return null;
      } else {
        let valorActual: TarjetaProximasSalidas = null;
        let valorAnterior: TarjetaProximasSalidas = null;
        //let valorSiguiente: TarjetaProximasSalidas = null

        [valorActual] = this.DataProximasSalidas.filter(f => f.Id == id);
        let turnoActual: number = valorActual.TurnoDeSalida;

        // Si hay un elemento anterior, devolver su CityId
        valorAnterior = indice > 0 ? this.DataProximasSalidas[indice - 1] : null;
        let turnoAnterior: number = valorAnterior.TurnoDeSalida;

        // Si no hay anterior pero hay un siguiente, devolver el siguiente
        //valorSiguiente = indice === 0 && this.DataProximasSalidas.length > 1 ? this.DataProximasSalidas[1] : null;


        if (valorAnterior != null) {
          for (var i = 0; i < this.DataProximasSalidas.length; i++) {
            if (this.DataProximasSalidas[i].Id == valorActual.Id) {
              this.DataProximasSalidas[i].TurnoDeSalida = turnoAnterior;
            }

            if (this.DataProximasSalidas[i].Id == valorAnterior.Id) {
              this.DataProximasSalidas[i].TurnoDeSalida = turnoActual;
            }
          }

          this.DataProximasSalidas = Enumerable.from(this.DataProximasSalidas).orderBy(o => o.TurnoDeSalida).toArray();
        }
      }
    } else {
      console.log("habilite la opcion");
      //alert("habilite la opcion")
    }

  }

  BajarTarjeta(id: string) {
    if (this.enabledDisabledImg == true) {
      let indice = this.DataProximasSalidas.findIndex(tab => tab.Id === id);
      // Si no se encuentra el elemento, devolver null
      if (indice === -1) {
        console.log("Indice no encontrado")
        return null
      } else {
        let valorActual: TarjetaProximasSalidas = null;
        //let valorAnterior: TarjetaProximasSalidas = null;
        let valorSiguiente: TarjetaProximasSalidas = null;

        [valorActual] = this.DataProximasSalidas.filter(f => f.Id == id);
        let turnoActual: number = valorActual.TurnoDeSalida;

        // Si hay un elemento anterior, devolver su CityId
        //valorAnterior = indice > 0 ? this.DataProximasSalidas[indice - 1] : null;
        //let turnoAnterior: number = valorAnterior.TurnoDeSalida;

        // Si no hay anterior pero hay un siguiente, devolver el siguiente
        valorSiguiente = (indice >= 0 && this.DataProximasSalidas.length > 1) ? this.DataProximasSalidas[indice + 1] : null;
        let turnoSiguiente: number = valorSiguiente.TurnoDeSalida;

        if (valorSiguiente != null) {
          for (var i = 0; i < this.DataProximasSalidas.length; i++) {
            if (this.DataProximasSalidas[i].Id == valorActual.Id) {
              this.DataProximasSalidas[i].TurnoDeSalida = turnoSiguiente;
            }

            if (this.DataProximasSalidas[i].Id == valorSiguiente.Id) {
              this.DataProximasSalidas[i].TurnoDeSalida = turnoActual;
            }
          }

          this.DataProximasSalidas = Enumerable.from(this.DataProximasSalidas).orderBy(o => o.TurnoDeSalida).toArray();
        }
      }
    }
    //else {
    //  console.log("habilite la opcion");
    //}  
  }

  onSearchChange(id: string, searchValue: number) {
    let idLista: string = id;
    let value: number = searchValue;

    let [tarjetaProximaSalida] = this.DataProximasSalidas.filter(f => f.Id == idLista);
    tarjetaProximaSalida.NuevaPosicionSalida = value;
  }

  ReordenarProximasSalidas() {
    //let posiciones: Array<number> = [];
    //posiciones = Enumerable.from(this.DataProximasSalidas).select(s => s.NuevaPosicionSalida).toArray();

    //let posicion: number;
    //posicion = posiciones[0];

    //let validar: boolean = false;

    //for (var i = 0; i < posiciones.length; i++) {
    //  if (i == posicion) {
    //    this.DialogService.NotificacionWarning("Posición " + i + " duplicada");
    //    validar = true;
    //    break;
    //  } else {
    //    validar = false;
    //  }
    //}

    //if (validar == true) {
    //  alert("Incorrecto")
    //} else {
    //  alert("Correcto")
    //}
     
  }

}

export interface ProximasSalidas {
  Id: number;
  Gestion: string;
  Descripcion: string;
  PosicionSalida: number;
  NuevaPosicionSalida?: number;
  Factura: string;
  Peso: string;
  RealizadoPor: string;
}

export interface PalletsEnSitio {
  Id: number;
  Gestion: string;
  Descripcion: string;
  PosicionSalida: number;
  Factura: string;
  Peso: string;
  RealizadoPor: string;
}
