export const environment = {
  production: false,
  authentication: { ClienteId: 'WAREHOUSEJS', SistemaId: 'd5375da8-0e2d-4dfb-8b01-199ac729ac77', Stage: true },
  fileshare: { CodigoSistema: 4 },
  firebase: {
    apiKey: "AIzaSyD0ktZx94hGtbHi7dw2N2H9mEAQPO6Txr0",
    databaseURL: "https://notificacionpushdev.firebaseio.com",
    messagingSenderId: "514261864933",
    projectId: "notificacionpushdev",
    appId: "1:514261864933:web:c1cc117677e037bf0b5f0c",
    PublicVapidKey: "BHVFl0D2ZFpy7pRHfYDN1Sa1yMzn1P8cxZiEZ94CUwBIMC2aKK3TPzD5vgNzp8W4POU1x_UcE0jxIiKYEZzC1pg",
    SistemaId: 'd5375da8-0e2d-4dfb-8b01-199ac729ac77'
  },
  webServices: {
    //URL Prod
    //WareHouse: 'https://warehouseapivesta-stg.azurewebsites.net/api/'

    //URL stage
    WareHouse: 'https://stgvestawarhouapi.azurewebsites.net/api/'

    //URL escenarios
    //WareHouse: 'https://scewarehouseapi.vestadev-accelerate.com/api/'
  },
  web: {
    Remisiones: 'https://controldedocumentos-stage.azurewebsites.net/remisiones/',
    //ModeloLogistico: 'https://modelologisticoderedope.vesta-accelerate.com/guiagestion/GestionUnica?codigoGestion='
    ModeloLogistico: 'https://modelologisticoderedwebapi-stage.azurewebsites.net/guiagestion/GestionUnica?codigoGestion=' 
  }
};
