import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroPalletsEnSitio'
})
export class FiltroPalletsEnSitioPipe implements PipeTransform {

  transform(snapshotArr: any, searchValue: string) {
    if (!snapshotArr) {
      return [];
    }
    if (!searchValue) {
      return snapshotArr;
    }
    if (snapshotArr && searchValue) {
      return snapshotArr.filter(snapshot => {
        return snapshot.Gestion.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
  }

}
