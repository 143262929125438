import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { VestaModule } from 'vesta';
import { AppComponent } from './app.component';
//import { GestionesModule } from './screen/gestiones/gestiones.module';
//import { RevisionAjusteModule } from './screen/revision-ajuste/revision-ajuste.module';
//import { AsignacionSegmentosModule } from './screen/asignacion-segmentos/asignacion-segmentos.module';
//import { PickingModule } from './screen/picking/picking.module';
//import { SolicitudmateriaPrimaModule } from './screen/solicitudmateria-prima/solicitudmateria-prima.module';
//import { GestionInventarioModule } from './screen/gestion-inventario/gestion-inventario.module';
//import { GestionInventarioCopiaModule } from './screen/gestion-inventario-copia/gestion-inventario-copia.module';
//import { RevisionIngresoModule } from './screen/revision-ingreso/revision-ingreso.module';
//import { InventariosModule } from './screen/inventarios/inventarios.module';
//import { MigoSapModule } from './screen/migo-sap/migo-sap.module';
//import { MiroSapModule } from './screen/miro-sap/miro-sap.module';
//import { TrasladosSapModule } from './screen/traslados-sap/traslados-sap.module';
//import { CorteDiarioModule } from './screen/corte-diario/corte-diario.module';
//import { JqxGridModule } from './ng-modules/jqx-grid/jqx-grid.module';
//import { JqxTabsModule } from './ng-modules/jqx-tabs/jqx-tabs.module';

import { ReporteTrackingFacturasNacionalesModule } from './screen/reporte-tracking-facturas-nacionales - Copy/reporte-tracking-facturas-nacionales.module';
import { RequisasModule } from './screen/requisas/requisas.module';
import { VestaMenuModule } from 'vesta-menu';
import { VestaAuthenticationModule } from 'vesta-authentication';
import { VestaFirebaseModule } from 'vesta-firebase';

import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
      AppComponent      
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      VestaAuthenticationModule.forRoot(environment.authentication),
      VestaMenuModule,
      VestaModule.forRoot({ isAuthentication: true }),
      VestaFirebaseModule.forRoot(
        environment.firebase
        , { production: false }),
      //JqxGridModule,
      //JqxTabsModule,
      //GestionesModule,
      //RevisionAjusteModule,
      //AsignacionSegmentosModule,
      //PickingModule,
      //SolicitudmateriaPrimaModule,
      //RevisionIngresoModule,
      //GestionInventarioModule,
      //GestionInventarioCopiaModule,
      //InventariosModule,
      //MigoSapModule,
      //MiroSapModule,
      //TrasladosSapModule,
      ReporteTrackingFacturasNacionalesModule,
      RequisasModule
      //CorteDiarioModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})

export class AppModule {
}
