import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-ajustes-carga',
  templateUrl: './grid-ajustes-carga.component.html',
  styleUrls: ['./grid-ajustes-carga.component.css']
})
export class GridAjustesCargaComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridAjustesCarga', { static: false }) myGridAjustesCarga: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Sku', type: 'string' },
        { name: 'SkuCodigo', type: 'string' },
        { name: 'Cantidad', type: 'number' },
        { name: 'UnidadMedida', type: 'string' },
        { name: 'PrecioFactura', type: 'number' },
        { name: 'PrecioOc', type: 'number' },
        { name: 'Diferencia', type: 'number' }
      ],
      id: "Id",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Sku', datafield: 'Sku', cellsalign: 'left', align: 'center', width: '25%' },
      { text: 'Código', datafield: 'SkuCodigo', cellsalign: 'center', align: 'center', width: '13%' },
      { text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'UM', datafield: 'UnidadDeMedida', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Precio Factura', datafield: 'OrdendeCompra', cellsalign: 'center', align: 'center', filterable: false, width: '14%' },
      { text: 'Precio OC', datafield: 'Ajustes', cellsalign: 'center', align: 'center', filterable: false, width: '14%' },
      { text: 'Diferencia Precio', datafield: 'Diferencia', cellsalign: 'center', align: 'center', filterable: false, width: '14%' }
    ];
}
