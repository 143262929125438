import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { RequisasComponent } from './requisas/requisas.component';
import { FiltroProximasSalidasPipe } from './pipes/filtro-proximas-salidas/filtro-proximas-salidas.pipe';
import { FiltroPalletsEnSitioPipe } from './pipes/filtro-pallets-en-sitio/filtro-pallets-en-sitio.pipe';

const routes: Routes = [
  { path: '', component: RequisasComponent }
]
const routing = RouterModule.forChild(routes);

@NgModule({
  declarations: [RequisasComponent, FiltroProximasSalidasPipe, FiltroPalletsEnSitioPipe],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    routing
  ]
})

export class RequisasModule { }
