import { Component, OnInit, ViewChild  } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-ajustes-precio',
  templateUrl: './grid-ajustes-precio.component.html',
  styleUrls: ['./grid-ajustes-precio.component.css']
})
export class GridAjustesPrecioComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridAjustesPrecio', { static: false }) myGridAjustesPrecio: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Sku', type: 'string' },
        { name: 'Cantidad', type: 'number' },
        { name: 'UnidadMedida', type: 'string' },
        { name: 'PrecioFactura', type: 'number' },
        { name: 'PrecioOc', type: 'number' },
        { name: 'DiferenciaPrecio', type: 'number' }
      ],
      id: "Sku",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      { text: 'Sku', datafield: 'Sku', cellsalign: 'left', align: 'center', width: '30%' },
      { text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '10%' },
      { text: 'Unidad Medida', datafield: 'UnidadMedida', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      { text: 'Precio Factura', datafield: 'PrecioFactura', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      { text: 'Precio OC', datafield: 'PrecioOc', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      { text: 'Diferencia Precio', datafield: 'DiferenciaPrecio', cellsalign: 'center', align: 'center', filterable: false, width: '15%' }
    ];
}
