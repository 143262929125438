import { Component, Inject, OnInit } from '@angular/core';
import { IUserAuth, USER_AUTH, IAuthService, AUTH_SERVICE } from 'vesta-authentication';
import { WebPushFirebaseService } from 'vesta-firebase';
import { DialogService } from 'vesta';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  //template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  constructor(
    @Inject(USER_AUTH) private userAuth: IUserAuth,
    @Inject(AUTH_SERVICE) private authService: IAuthService,
    private webPush: WebPushFirebaseService,   
    private DialogService: DialogService
  ) { }

  user: IUserAuth = this.userAuth;

  ngOnInit() {
    this.userAuth.UserEvent.subscribe(async response => {
      if (response.Id == null) return void 0;
      this.user = response;
      let resp = await this.NotificacionFirebase();
      if (resp == false) return void 0;

      let mensajes = await this.webPush.GetMensajesUsuario();
    });
  }

  NotificacionFirebase() {
    let promise = new Promise<boolean>(async (resolve, reject) => {
      let resp = await this.webPush.RequestPermission(this.user);

      this.webPush.OnMessaging.subscribe(payload => {
        this.DialogService.NotificacionWarning(payload.notification.body);
      });

      resolve(resp);
    })

    return promise;
  }  

  Logout() {   
    this.authService.signoutRedirect();
  }
}
